import React from "react"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import Section from "../../components/section/Section"
import css from "./Aid.module.css"
import ButtonLink from "../../components/button/ButtonLink"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.aid}
      slug={url}
      title="¿Eres víctima de violencia de género?"
      theme="blog"
    >
      <Section className={css.header}>
        <div className={css.headerContainer}>
          <h1>¿Eres víctima de violencia de género?</h1>
          <p>{data.aid.descripcion.descripcion}</p>
        </div>
      </Section>

      <Section className={css.actions}>
        <div className={css.actionsContainer}>
          {data.aid.acciones.map((accion, i) => (
            <div key={i} className={css.action}>
              <h2>{accion.nombre}</h2>
              <Markdown>{accion.cuerpo.childMarkdownRemark.html}</Markdown>
              <div className={css.institutions}>
                {accion.instituciones.map((institucion, i) => (
                  <div key={i} className={css.institution}>
                    <h3>{institucion.nombre.nombre}</h3>
                    <Markdown>
                      {institucion.descripcion.childMarkdownRemark.html}
                    </Markdown>
                    {institucion.url && (
                      <ButtonLink
                        url={institucion.url}
                        className={css.linkToInstitution}
                        external
                      >
                        Ir
                      </ButtonLink>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Section>
    </Layout>
  )
}
